import { BeautifulDisplayComponent } from './../beautiful-display/beautiful-display.component';
import { Component, OnInit } from '@angular/core';
import { Starthere2Component } from '../starthere2/starthere2.component';

@Component({
  selector: 'app-truck-row',
  standalone: true,
  imports: [Starthere2Component, BeautifulDisplayComponent],
  templateUrl: './truck-row.component.html',
  styleUrls: ['./truck-row.component.scss'],
})
export class TruckRowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
