<!-- <app-top-nav></app-top-nav> -->
<app-hero-pic></app-hero-pic>

<!-- converted to standalone -->
<app-truck-row></app-truck-row>
<app-homepage-intro></app-homepage-intro>
<!-- <app-replacements></app-replacements> NOT CURRENTLY USED -->

<app-factorytrained></app-factorytrained>
<app-lets-build-row></app-lets-build-row>
<app-still-have-questions></app-still-have-questions>

